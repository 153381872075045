import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Chip,
  Grid,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import ProjectContext from 'app/contexts/ProjectContext';
import ShoppingContext from 'app/contexts/ShoppingContext';
import StoreContext from 'app/contexts/StoreContext';
import { IDataShopping } from 'app/types/data/IDataShopping';
import { IDataSkuShowcase } from 'app/types/data/IDataSku';
import { IDataVirtualShowcase } from 'app/types/data/IDataVirtualShowcase';
import { moneyBR } from 'app/utils/format';
import React from 'react';
import ProductCarousel from './ProductCarousel';
import QRCodeImage from 'app/components/QRCodeImage';
import Parametro_Api from 'app/api/Parametro_Api';
import { set } from 'lodash';

const Container = styled(Paper)(({ theme }) => ({
  marginLeft: -24,
  padding: theme.spacing(3),
}));

const ContainerPrice = styled('div')(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(2),
}));

const GridCarousel = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 6),
}));

const PriceFrom = styled(Typography)(({ theme }) => ({
  display: 'block',
  textDecoration: 'line-through',
  color: theme.palette.grey[600],
}));

const PriceTo = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontSize: 24,
  marginTop: -10,
}));

const ContainerAtributos = styled('div')(({ theme }) => ({
  display: 'block',
  margin: theme.spacing(2, 0),
}));

const BlockAtributos = styled('div')(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(1),
}));

interface SectionNameProps {
  margin?: string;
  padding?: string;
}

const SectionName = styled(Typography)<SectionNameProps>(({ theme, margin, padding }) => ({
  display: 'block',
  fontSize: 14,
  fontWeight: 600,
  margin: margin || theme.spacing(1, 0, 1),
  padding: padding || '8px',
}));

const SectionNameFlex = styled(SectionName)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: 5,
  margin: theme.spacing(3, 0, 2),
}));

const AtributoValuesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
}));

const AtributoTag = styled(Chip)(({ theme }) => ({
  marginRight: 4,
}));

declare interface IViewShowcaseProduct {
  product: IDataVirtualShowcase;
}

const ViewShowcaseProduct = (props: IViewShowcaseProduct) => {
  const qrCodeLink = window.location.origin + `/portal/vitrine-virtual/${props.product.id}`;
  const isInVitrineVirtual = props?.product?.vitrine_virtual;
  const projectCtxt = React.useContext(ProjectContext);
  const shoppingCtxt = React.useContext(ShoppingContext);
  const storeCtxt = React.useContext(StoreContext);

  const [skuActual, setSkuActual] = React.useState<IDataSkuShowcase | null | undefined>(
    props.product?.skus && props.product?.skus?.length > 0 ? props.product.skus[0] : null
  );

  const [paramPrice, setParamPrice] = React.useState<boolean>(false);
  const [paramStock, setParamStock] = React.useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState<string[]>([]);


  const [atributos, setAtributos] = React.useState<any[] | undefined>([]);

  React.useEffect(() => {
    let bread: string[] = [];
    if (projectCtxt) {
      bread.push(projectCtxt.nm_projeto);
    }
    Parametro_Api.showByName("Exibir preço na vitrine ?").then(f => {
      setParamPrice(f.valor === '1');
    })

    Parametro_Api.showByName("Exibir estoque ?").then(f => {
      setParamStock(f.valor === '1');
    })


    if (
      shoppingCtxt ||
      (props.product.loja?.model && (props.product.loja?.model as IDataShopping).nm_shopping)
    ) {
      if (shoppingCtxt) bread.push(shoppingCtxt.nm_shopping!);
      else if ((props.product.loja?.model as IDataShopping).nm_shopping)
        bread.push((props.product.loja?.model as IDataShopping).nm_shopping!);
    }

    if (storeCtxt || (props.product.loja && props.product.loja.nm_loja)) {
      if (storeCtxt) bread.push(storeCtxt?.nm_loja);
      else if (props.product.loja?.nm_loja) bread.push(props.product.loja?.nm_loja);
    }

    if (skuActual) {
      bread.push(skuActual?.smart_description);
    }
    setBreadcrumbs(bread);
    const values = props.product.atributos?.filter(x => (x.values ?? []).filter(v => !!v).length > 0)
    setAtributos(values);
  }, [props.product, skuActual, projectCtxt, shoppingCtxt, storeCtxt]);

  function isAttributteSelected(attribute: string, value: string): boolean {
    if (!skuActual) return false;
    const attrSku = skuActual.atributos.filter((attr) => {
      return attr.attribute === attribute;
    });

    return attrSku[0] && attrSku[0].value === value;
  }

  function changeSKU(attribute: string, valueAttribute: string) {
    if (skuActual && props.product?.skus) {
      var attributesSku = skuActual?.atributos;

      const attrSku = attributesSku.filter((attr) => {
        return (attr.attribute ?? attr['nm_atributo']) !== attribute;
      });

      attributesSku = [...attrSku, { attribute, value: valueAttribute }];

      const skuSelect = props.product?.skus.filter((sku) => {
        return attributesSku.every((attrA) => {
          return sku.atributos.some(
            (attrB) => attrB.attribute === attrA.attribute && attrB.value === attrA.value
          );
        });
      });

      if (skuSelect.length > 0) {
        setSkuActual(skuSelect[0]);
      } else {
        const skuSelectAvailable = props.product?.skus.filter((sku) => {
          const anyAttr = sku.atributos.filter((attr) => attr.value === valueAttribute);
          return anyAttr.length > 0;
        });
        setSkuActual(skuSelectAvailable[0]);
      }
    }
  }

  return (
    <Container elevation={1}>
      {skuActual ? (
        <Grid container spacing={4}>
          <Grid container item xs={12} direction="row" alignItems="center">
            <Grid item xs={12} sm={10} sx={{ marginBottom: '10px' }}>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs.map((bread, key) => (
                  <Typography key={key} color="text.primary">
                    {bread}
                  </Typography>
                ))}
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end">
              <div>
                {isInVitrineVirtual && (
                  <QRCodeImage
                    downloadFileName={`qr-code-${props?.product?.id || ''}`}
                    qrCodeLink={qrCodeLink}
                    size={80}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <GridCarousel item xs={12} md={12} xl={7}>
            <ProductCarousel media={skuActual.medias} />
          </GridCarousel>

          <Grid item xs={12} md={12} xl={5}>
            <Typography variant="h5" component="h2">
              {skuActual.smart_description || skuActual.name}
            </Typography>
            <Typography variant="caption">SKU: {skuActual.sku}</Typography>
            {paramPrice && <ContainerPrice>
              {skuActual.vl_precode && skuActual.vl_precopor && Number(skuActual.vl_precode) > Number(skuActual.vl_precopor) && (
                <PriceFrom variant="caption">R${moneyBR(skuActual.vl_precode)}</PriceFrom>
              )}
              {skuActual.vl_precopor &&
                <PriceTo variant="button">R${moneyBR(skuActual.vl_precopor)}</PriceTo>
              }
            </ContainerPrice>}
            <ContainerAtributos>
              {atributos?.map((atributo, key) => (
                <BlockAtributos key={key}>
                  {atributo.attribute &&
                    <SectionName variant="body2">{atributo.attribute}</SectionName>
                  }
                  {atributo?.values &&
                    <AtributoValuesContainer>
                      {(atributo?.values ?? []).map((val, index) => (
                        <AtributoTag
                          color={
                            isAttributteSelected(atributo.attribute, val) ? 'secondary' : 'default'
                          }
                          variant={
                            isAttributteSelected(atributo.attribute, val) ? 'filled' : 'outlined'
                          }
                          label={val}
                          key={index}
                          onClick={() => changeSKU(atributo.attribute, val)}
                        />
                      ))}
                    </AtributoValuesContainer>
                  }
                </BlockAtributos>
              ))}
            </ContainerAtributos>
            <Box display="flex" gap={2}>
              {paramStock && skuActual?.nr_estoque && <SectionNameFlex variant="body2">
                <span>Estoque Disponível:</span>

                <Typography>{skuActual?.nr_estoque.toString().replace('.', ',')}</Typography>
              </SectionNameFlex>}

              <SectionNameFlex variant="body2">
                <span>Status:</span>
                <Typography>{skuActual?.status_umami?.label}</Typography>
              </SectionNameFlex>
            </Box>
            <Box>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel-description"
                >
                  <SectionName variant="body2">Descrição</SectionName>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1">{props.product.ds_produto}</Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            {props.product.ficha_tecnica && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel-description"
                >
                  <SectionName variant="body2">Ficha Técnica</SectionName>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1">
                    <div dangerouslySetInnerHTML={{ __html: props.product.ficha_tecnica }} />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
            {!!props?.product?.especificacao_tecnica?.length && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel-description"
                >
                  <SectionName variant="body2">Especificações Técnicas</SectionName>
                </AccordionSummary>
                <AccordionDetails>
                  {props.product.especificacao_tecnica?.map((esp) => (
                    <Box display="flex" width="100%" borderBottom="1px solid #dbdbdb">
                      <SectionName
                        p={1}
                        variant="body2"
                        style={{ width: '50%', backgroundColor: '#f1f1f1' }}
                      >
                        {esp.attribute}
                      </SectionName>
                      <Box
                        display="flex"
                        flexDirection="column"
                        flexGrow={1}
                        justifyContent="center"
                      >
                        {esp.values?.map((val) => (
                          <SectionName
                            p={1}
                            variant="body2"
                            margin={esp.values?.length > 1 ? '2px 0px' : undefined}
                            padding={esp.values?.length > 1 ? '0 0 0 8px' : undefined}
                          >
                            {val}
                          </SectionName>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <Typography variant="h4" component="h2">
            Produto sem SKUs cadastrado. Verifique.
          </Typography>
        </>
      )}
    </Container>
  );
};

export default ViewShowcaseProduct;
